.home-main-slider {
	min-height: 90vh;
	overflow: hidden;
	.banner-item {
		position: relative;
		height: 100%;
		width: 100%;
		overflow: hidden;
		.overlay {
			background: linear-gradient(0deg, rgba(0, 70, 117, 0.53), rgba(0, 70, 117, 0.53));
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;
			.slider-text {
				font-weight: 800;
				font-size: 3vw;
				width: 80%;
			}
		}
		// .content{
		//     position: absolute;
		// }
	}
}
.banner-indicator {
	position: absolute;
	bottom: 20px;
	transform: translateX(-50%);
	left: 50%;
}
