.course-details {
	.course-details-title {
		background: #dee2e6;
		color: #034d80;
	}
	.full-table {
		table {
			width: 100%;
			td {
				border: 1px solid #dbdbdb !important;
			}
		}
	}
	.card-wrapper {
		color: #034d80;
	}
}
