@import "../../Styles/Variable.scss";
.custom-button{
    padding: 10px 40px;
    text-transform: uppercase;
    border: 2px solid;
    border-color: white;
    display: inline-block;
    font-weight: 600;
    transition: 0.3s ease-in-out all;
    cursor: pointer;
    &.button-solid{
        &.button-primary{
            background: $primaryColor;
            border-color: $primaryColor;
            color: white;
            &:hover{
                color: $primaryColor;
            }
        }
        &.button-secondary{
            background: $secondaryColor;
            border-color: $secondaryColor;
            color: white;
            &:hover{
                color: $secondaryColor;
            }
        }
        &:hover{
            background: transparent;
        }
    }
    &.button-bordered{
        &.button-primary{
            border-color: $primaryColor;
            &:hover{
                background: $primaryColor;
                color: white;
            }
        }
        &.button-secondary{
            border-color: $secondaryColor;
            &:hover{
                background: $secondaryColor;
                color: white;
            }
        }
    }
}