.class--slider {
	.slick-list {
		// overflow: visible;
	}
	.slick-dots {
		padding-top: 50px;
	}
	.slick-track {
		margin-bottom: 40px;
	}
	.slick-arrow {
		&:before {
			color: black;
		}
	}
}
