@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1340px;
	}
}
@media (min-width: 1600px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1540px;
	}
}
p {
	font-family: "Roboto";
}

.form-control {
	padding: 10px 20px;
	height: 50px;
	box-shadow: 0px 0px 25px #0000001c;
	border: none;
	font-weight: 600;
	border: 1px solid #0571bb;
	border-radius: 10px;
	&:focus {
		outline: none;
		border: none;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
	}
	&[type=radio] {
		width: 40px;
		display: inline-flex;
		margin-right: 10px;
		border: none;
	}
}
.form-group {
	label {
		color: gray;
		font-size: 14px;
	}
}
select {
	&.form-control {
		font-weight: bold;
	}
}
.text-bold {
	font-weight: bold;
}
.form-group {
	.form-error {
		color: red;
		padding: 10px 0px;
		font-size: 12px;
	}
}
.gender-select {
	label {
		width: 80px;
		height: 80px;
		border-radius: 10px;
		box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
		margin-right: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: 0.2s ease-in-out all;
		cursor: pointer;
		&.active {
			background: #f0ad2b;
			color: white;
		}
	}
}

.paragraph {
	font-size: 18px;
	line-height: 34px;
	&.small {
		font-size: 16px;
	}
}
