@import "./Variable.scss";
@media only screen and (max-width: 992px) {
    .main {
        padding-top: 65px !important;
        max-width: 100vw;
        overflow: hidden;
    }
    .mobile-navigation {
        position: fixed;
        z-index: 10;
        background: white;
        width: 100%;
        height: 65px;
    
        top: 0;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.09);
        overflow: hidden;
        transition: 0.5s ease-in-out all;
        
        .menu-shortcut {
            transform: rotate(180deg);
    
        }
        .top-bar-stripe{
            padding: 0px 15px;
            display: flex;
            justify-content: space-between;
            padding-right: 30px;
            height: 65px;
        }
        &.active{
            height: 100vh;
        }
        .bottom-details{
            overflow-y: scroll;
            height: calc(100% - 65px);
            padding-bottom: 20px;
            .button-style{
                border: 1px solid $primaryColor;
            }
            .sub-navigation-mobile{
                height: 0px;
                overflow: hidden;
                &.active{
                    height: auto;
                }
                a{
                    color: $linkColor;
                }
            }
        }
    }
    
    
    //banner items
    .home-main-slider {
        height: 60vh !important;
        min-height: unset !important;
    
        .banner-item {
            height: 60vh !important;
    
            img{
                height: 100%;
                width: auto;
            }
            .slider-text{
                font-size: 10vw !important;
            }
        }
    }
    .imageloader {
        img{
            width: 100%;
        }
    }
    .title-description{
        width: 100% !important;
        padding: 10px
    }
    .footer-wrapper{
        img{
            height: 70px !important;
        }
    }
}
