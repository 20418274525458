$theme-colors: ();
$headings-font-family: 'Montserrat', sans-serif;
@import '~bootstrap/scss/bootstrap';
@import '../Styles/CustomLayout.scss';
@import '../Styles/Animation.scss';
@import '../Styles/responsive.scss';

body {
	width: 100%;
	overflow-x: hidden;
	background: #f2f2f2;
	color: #0562a0;
}
.table {
	color: #0562a0;
}
