@import '../../Styles/Variable.scss';
.footer-wrapper {
	background: #034d80;
	.footer-top {
		h4 {
			color: #b0d3eb;
			font-weight: bold;
		}
		border-bottom: 5px solid $secondaryColor;
	}
	.footer-bottom {
		background: #023e66;
		span {
			color: #a4a4a4;
			font-size: 16px;
			padding: 10px 0px;
			cursor: pointer;
			font-family: "Roboto";
			transition: 0.3s ease-out;
			&:hover {
				color: #b0d3eb;
			}
		}
		h4 {
			color: #ffffff;
			font-family: "Roboto";
			padding: 10px 0px;
			text-decoration: underline;
		}
		h4 {
			color: #ffffff;
			font-weight: 500;
		}
		.contact {
			.item {
				svg {
					margin-right: 20px;
				}
			}
		}
		.copy-right {
			color: #f37920;
			font-size: 16px;
			font-family: 'Roboto';
		}
	}
}
