@import "../../Styles/Variable.scss";
.course-card-wrapper {
	cursor: pointer;
	// margin-bottom: 60px;
	background: #ffffff;
	box-shadow: 0px 0px 32px rgba(0, 0, 0, 0);
	border-radius: 13.3019px;
	img {
		width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.course-details {
		padding: 10px 30px;
		.short-name {
			border: 1px solid $primaryColor;
			margin-top: 20px;
			padding: 3px 15px;
			border-radius: 5px;
			font-weight: 600;
			font-size: 12px;
			color: #0071bc;
			// background: #07489f1a;
			color: $primaryColor;
		}
		h5 {
			font-weight: 800;
			margin-top: 20px;
			text-align: center;
		}
		p {
			font-weight: 500;
		}
	}
}
