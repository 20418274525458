@import "../../Styles/Variable.scss";

.event-wrapper {
	padding: 30px 30px;
	padding-top: 80px;
	height: 100%;

	.title {
		height: 60px;
		font-weight: 800;
		text-transform: uppercase;
	}

	.event-container {
		border-radius: 10px;
		height: 100%;

		img {
			width: 100%;
			border-top-right-radius: 10px;
			border-top-left-radius: 10px;
		}

		.event--details {
			padding: 20px;
			padding-top: 30px;

			h4 {
				font-size: 20px;
				font-weight: 700;
				color: #034d80;
			}

			em {
				color: $primaryColor;
			}

			p {
				font-weight: 400;
			}
		}
	}

	&.event-primary {
		background: $primaryColor;
		color: white;

		p {
			color: white !important;
		}

		em {
			color: white !important;
		}

		.event--details {
			padding: 10px 0px;
			padding-top: 30px;
			h4 {
				color: white;
			}
		}
	}

	&.event-default {
		padding: 30px 0px;
		padding-top: 80px;

		.event-container {
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
			background: white;
		}
	}
}
