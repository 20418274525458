@import "../../../Styles/Variable.scss";
.event--section{
    position: relative;
    &::after{
        content: '';
        position:absolute;
        height: 30%;
        width: 100%;
        bottom: 0px;
        left: 0px;
        background: $secondaryColor;
        z-index: -2;
    }
}