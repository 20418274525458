.custom-navigation {
    z-index: 5;
    width: 100%;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out all;

    &.solid-nav {
        background: #0071bc !important;
        box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.42);
    }

    .nav-link {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bolder;
        text-transform: uppercase;
        color: white;
        padding: 0.5rem 0.8rem !important;
    }

    .dropdown--menu {
        .dropdown-menu {
            .dropdown-item {
                padding: 10px 20px;
            }
        }
    }
}