@import "../../Styles/Variable.scss";
.team-item-wrapper {
	.member-details {
		h3 {
			font-weight: bold;
			// text-transform: uppercase;
			font-size: 16px;
		}
		p {
			font-size: 18px;
		}
		h6 {
			color: $primaryColor;
		}
	}
	.team-image {
		img {
			width: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
	}
}
