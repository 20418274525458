@import "../../Styles/Variable.scss";
.section-title-wrapper {
	.title-content {
		// padding: 40px;
		margin: 40px 0px;
		text-transform: uppercase;
		h2 {
			font-weight: 800;
			font-size: 1.5rem;
			background: -webkit-linear-gradient(45deg, #f6ab23, #0271bc);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		position: relative;
		&::after {
			content: '';
			background: $primaryColor;
			width: 48%;
			position: absolute;
			left: 0px;
			bottom: 0px;
			height: 4px;
		}
		&::before {
			content: '';
			background: $secondaryColor;
			width: 48%;
			position: absolute;
			right: 0px;
			bottom: 0px;
			height: 4px;
		}
	}
	&.title-light {
		.title-content {
			color: white;
		}
	}
}
